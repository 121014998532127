// var baseUrl = 'http://devicerepair.api.dl-i.cn' // 接口地址
// var baseUrl = 'http://127.0.0.1:8081' // 接口地址
// var baseUrl = 'http://192.168.2.13:8081' // 接口地址
var baseUrl = 'https://devicerepair.api.szkanae.com' // 正式地址
export default {
  baseUrl: baseUrl,
  // 文件服务器地址，不需要可以删除
  minio: {
    url: "http://192.168.1.122:30090"
  },
  // 上传接口地址
  uploadFileUrl: {
    normal: baseUrl+'/Api/UploadFile',
    bigFile: baseUrl+'/Api/UploadFileResume/Save',
    minio: baseUrl+'/Api/MinIO/Upload',
    base64: baseUrl+'/Api/UploadFileBase64',
  },
  page: {
    total: 0, // 总条数
    current: 1, // 当前页
    size: 20, // 分页大小
  }
}