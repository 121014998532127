import { createRouter, createWebHistory } from 'vue-router'
var Console = () => import('./Console.vue')

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            redirect: '/Welcome'
        },
        {
            path: '/User/RoleAuthority/List',
            component: Console,
            children: [
                {
                    path: '',
                    component: () => import('./models/User/RoleAuthority/List.vue'),
                }
            ]
        },
        {
            path: '/Login',
            component: () => import('./models/Login.vue'),
        },
        {
            path: '/Welcome',
            component: Console,
            children: [
                {
                    path: '',
                    component: () => import('./models/Welcome.vue'),
                }
            ]
        },
        // 下面为自动生成
        {
            path: '/WorkOrderReport/WorkOrderReport',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/WorkOrderReport/WorkOrderReport/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/WorkOrderReport/WorkOrderReport/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/WorkOrderReport/WorkOrderReport/Info.vue'),
                }
            ]
        },
        {
            path: '/BusinessManagement/BusinessManagement',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/BusinessManagement/BusinessManagement/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/BusinessManagement/BusinessManagement/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/BusinessManagement/BusinessManagement/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Logs',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Logs/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Logs/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Logs/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Role',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Role/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Role/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Role/Info.vue'),
                }
            ]
        },
		{
            path: '/User/User',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/User/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/User/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/User/Info.vue'),
                }
            ]
        },
		{
            path: '/WorkOrder/WrokOrderAccept',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/WorkOrder/WrokOrderAccept/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/WorkOrder/WrokOrderAccept/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/WorkOrder/WrokOrderAccept/Info.vue'),
                },
                {
                    path: 'Assign',
                    component: () => import('./models/WorkOrder/WrokOrderAccept/Assign.vue'),
                },
                {
                    path: 'HistoryList',
                    component: () => import('./models/WorkOrder/WrokOrderAccept/HistoryList.vue'),
                }
            ]
        },
		{
            path: '/User/Logs',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Logs/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Logs/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Logs/Info.vue'),
                }
            ]
        },
		{
            path: '/User/Role',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/Role/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/Role/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/Role/Info.vue'),
                }
            ]
        },
		{
            path: '/User/User',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/User/User/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/User/User/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/User/User/Info.vue'),
                }
            ]
        },
		{
            path: '/System/SystemErrorLog',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/System/SystemErrorLog/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/System/SystemErrorLog/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/System/SystemErrorLog/Info.vue'),
                }
            ]
        },
		{
            path: '/ECharts/Line',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/ECharts/Line/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/ECharts/Line/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/ECharts/Line/Info.vue'),
                }
            ]
        },
		{
            path: '/ECharts/Bar',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/ECharts/Bar/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/ECharts/Bar/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/ECharts/Bar/Info.vue'),
                }
            ]
        },
		{
            path: '/ECharts/Pie',
            component: Console,
            children: [
                {
                    path: 'List',
                    component: () => import('./models/ECharts/Pie/List.vue'),
                },
                {
                    path: 'Form',
                    component: () => import('./models/ECharts/Pie/Form.vue'),
                },
                {
                    path: 'Info',
                    component: () => import('./models/ECharts/Pie/Info.vue'),
                }
            ]
        },
    ]
})