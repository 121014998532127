<template>
  <el-config-provider :locale="locale">
    <div id="app" style="height: 100%;
    display: flex;
    align-content: center;">
      <router-view></router-view>
    </div>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default {
  name: 'App',
  setup() {
    // 切换为中文
    let locale = zhCn
    return {
      locale,
    }
  }
}
</script>
